import { cast, flow, getSnapshot, Instance, SnapshotIn, types } from "mobx-state-tree";
import API from "../../boot/Api";
import { ISelectOptionsModelSnapShot, SelectOptionsModel, SelectOptionsDefault } from "./options.model";
import {
  getClinicIndicationsAPI,
  getDefaultIndicationsAPI
} from "../../services/api/administration.api";

export const UserFormModel = types
  .model("UserFormModel", {
    fieldId: types.identifier,
    name: types.string,
    value: types.optional(types.string, ""),
    valueMulti: types.optional(types.array(types.string), [""]),
    type: types.enumeration("FieldType", [
      "input", "select", "checkbox", "dateTime", "radio", "number", "email", "time", "textarea", "multiply", "password"
    ]),
    placeholder: types.optional(types.string, ""),
    isOptional: types.optional(types.boolean, false),
    isCreatable: types.optional(types.boolean, false),
    isCompliance: types.optional(types.boolean, false),
    validatePhone: types.optional(types.boolean, false),
    validateZip: types.optional(types.boolean, false),
    validateTimeZone: types.optional(types.boolean, false),
    options: types.optional(types.array(SelectOptionsModel), []),
    optionsAll: types.optional(types.array(SelectOptionsModel), []),
    originList: types.maybe(types.frozen([])),
    required: types.optional(types.boolean, false),
    defaultValue: types.optional(SelectOptionsDefault, { value: "", label: "" }),
    defaultValueMulti: types.maybe(types.frozen([])),
    isDisabled: types.optional(types.boolean, false),
    isChecked: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
    additionalText: types.optional(types.string, ""),
    maxLength: types.maybe(types.number)
  })
  .views(self => {
    return {
      get optionsSnapshot() {
        return getSnapshot(self.options);
      }
    };
  })
  .actions(self => {
    function setDefaultMultiValue(values: any) {
      self.defaultValueMulti = values;
    }

    function setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    }

    function setDisabled(isDisabled: boolean) {
      self.isDisabled = isDisabled;
    }
    return { setDefaultMultiValue, setLoading, setDisabled };
  }).actions(self => {
    function setIsOptional(value: boolean) {
      self.isOptional = value;
    }
    return { setIsOptional };
  })
  .actions((self) => {
    function setValue(newName: string) {
      self.value = newName;
    }
    function clearValue() {
      self.value = "";
      self.defaultValue = { value: "", label: "" };
    }
    function setDefaultValue(newDefaultValue: { value: string, label: string }) {
      self.defaultValue = newDefaultValue;
    }
    function setValueMultiply(newName: any) {
      self.valueMulti = newName;
    }
    function setChecked(isChecked: boolean) {
      self.isChecked = isChecked;
    }
    const checkZip = flow(function* (newValue) {
      try {
        const response = yield API.get(`ClinicPatients/PatientTimeZone?zip=${newValue}`);
        self.value = response.data.StandardName;
        return response.data;
      } catch (error) {
        self.value = "";
      }
    });
    const setOptions = function(options: ISelectOptionsModelSnapShot[]) {
      if (self.options) {
        self.options = cast(options);
      }
    };
    return { setValue, setDefaultValue, setValueMultiply, checkZip, setOptions, setChecked, clearValue };
  })
  .actions(self => {
    function setSuperOptions(values: any) {
      self.setOptions(values);
    }
    return { setSuperOptions };
  })
  .actions(self => {
    function getActiveObject() {
      const active = self.options.find((item: any) => item.value === self.value);
      return active || {};
    }
    return { getActiveObject };
  })
  .actions(self => {
    const getStatesOptions = flow(function* () {
      try {
        const response = yield API.get("ClinicPatients/States");
        self.options = response.data.map((item: any) => ({
          value: item.Abbreviation,
          label: item.USState,
          id: item.Id + ""
        }));
      } catch (error) {
        console.log("error happens");
      }
    });
    const getClinics = flow(function* () {
      function getAdditionalClinics(response: any) {
        return response
          .map((item: any) => ({
            value: item.Name,
            label: item.Name,
            id: item.Id + ""
          }));
      }
      try {
        const response = yield API.get("ClinicPhysicians");
        self.options = response.data.map((item: any) => ({
          value: item.Name + " " + item.LastName,
          label: item.Name + " " + item.LastName,
          id: item.Id + "",
          additionalClinics: getAdditionalClinics(item.AdditionalClinics)
        }));
      } catch (error) {
        console.log("error happens");
      }
    });

    const getAvailableDevice = flow(function* () {
      if (self.options) {
        self.setLoading(true);
        try {
          const responseFree = yield API.get("ClinicDevices/Free?jsonFilter={}");
          const response = yield API.get("ClinicDevices?jsonFilter={}");

          if (response.data && response.data.length) {
            self.originList = response.data;
            self.optionsAll = response.data
              .map((item: any) => ({
                value: item.SerialNumber,
                label: item.SerialNumber,
                isDisabled: item.Status !== 2,
                id: item.Id + "",
                startDate: (item && item.DeviceStatistics && item.DeviceStatistics.DeviceRecordingStartDate) || ""
              }));
          }

          if (responseFree.data && responseFree.data.length) {
            self.options = responseFree.data
              .map((item: any) => ({
                value: item.SerialNumber,
                label: item.SerialNumber,
                isDisabled: item.Status !== 2,
                id: item.Id + ""
              }));
          } else {
            self.options = [{
              value: "No Available devices",
              label: "No Available devices",
              isDisabled: true,
              id: "0"
            }] as any;
          }
          self.setLoading(false);
        } catch (error) {
          self.setLoading(false);
          console.log("error happens");
        }
      }
    });

    const getICD10 = async function() {
      const clinic: any = await getClinicIndicationsAPI();
      if (!clinic.data || clinic.data.length === 0) {
        const defaultD: any = await getDefaultIndicationsAPI();
        const options: ISelectOptionsModelSnapShot[] = defaultD.data.map((item: any) => ({
          label: item.Code + " " + item.Description,
          value: item.Code,
          id: item.Id + ""
        }));
        self.setOptions(options);
      } else {
        const options: ISelectOptionsModelSnapShot[] = clinic.data.map((item: any) => ({
          label: item.Code + " " + item.Description,
          value: item.Code,
          id: item.Id + ""
        }));
        self.setOptions(options);
      }
    };

    return { getStatesOptions, getClinics, getICD10, getAvailableDevice };
  })
  .actions(() => ({
    afterCreate() {
      // if(self.fieldId === 'state') {
      //     console.log("Created a new state!")
      //     self.getStatesOptions();
      // }
      // if(self.fieldId === 'orderingPhysician') {
      //     console.log("orderingPhysician")
      //     self.getClinics();
      // }
    }
  }));

export interface IUserFormModel extends Instance<typeof UserFormModel> {}
export interface IUserFormModelSnapShot extends SnapshotIn<typeof UserFormModel> {}
