import { IStudyModelSnapShot } from "./study.model";

export function getDurationOptions(count: number, disabledTo?: number) {
  const arr: any[] = [];
  for (let i = 1; i <= count; i++) {
    arr.push({
      value: i + "",
      label: i + "",
      id: i + "",
      isDisabled: disabledTo && i <= disabledTo
    });
  }
  return arr;
}
export function getHolterDays(days: number = 2) {
  const arr: any[] = [];
  for (let i = 1; i <= days; i++) {
    arr.push({
      value: i + "",
      label: i + "",
      id: i + ""
    });
  }
  return arr;
}
function getTachyValues(includeNone: boolean, startFrom?: number) {
  const arr: any[] = [];
  if (includeNone) {
    arr.push({
      value: "None",
      label: "None",
      id: "0"
    });
  }
  const startValue = startFrom || 140;
  for (let i = startValue; i <= 240; i = i + 5) {
    arr.push({
      value: i + "",
      label: i + "",
      id: i + ""
    });
  }
  return arr;
}
function getBradyValues(includeNone: boolean, endTo?: number) {
  const arr: any[] = [];
  if (includeNone) {
    arr.push({
      value: "None",
      label: "None",
      id: "0"
    });
  }
  const maxValue = endTo || 110;
  for (let i = 25; i <= maxValue; i = i + 5) {
    arr.push({
      value: i + "",
      label: i + "",
      id: i + ""
    });
  }
  return arr;
}
function getPauseValues(includeNone: boolean, startFrom?: number) {
  const arr: any[] = [];
  if (includeNone) {
    arr.push({
      value: "None",
      label: "None",
      id: "0"
    });
  }
  const startValue = startFrom || 2;
  for (let i = startValue; i <= 6; i++) {
    arr.push({
      value: i + "",
      label: i + "",
      id: i + ""
    });
  }
  return arr;
}
function getTachyNumbers(includeNone: boolean) {
  const arr: any[] = [];
  if (includeNone) {
    arr.push({
      value: "None",
      label: "None",
      id: "0"
    });
  }
  for (let i = 5; i <= 10; i++) {
    arr.push({
      value: i + "",
      label: i + "",
      id: i + ""
    });
  }
  return arr;
}
function getBradyNumbers(includeNone: boolean) {
  const arr: any[] = [];
  if (includeNone) {
    arr.push({
      value: "None",
      label: "None",
      id: "0"
    });
  }
  for (let i = 3; i <= 10; i++) {
    arr.push({
      value: i + "",
      label: i + "",
      id: i + ""
    });
  }
  return arr;
}

export const InitialState: IStudyModelSnapShot = {
  id: "0",
  availableDevice: {
    name: "Available Device",
    value: "",
    type: "select",
    fieldId: "availableDevice",
    options: []
  },
  shipToHome: {
    name: "Ship to Home",
    value: "false",
    type: "checkbox",
    fieldId: "shipToHome"
  },
  serviceType: {
    name: "Service Type",
    value: "",
    type: "select",
    fieldId: "serviceType",
    // defaultValue: {
    //   value: "1",
    //   label: "MCT"
    // },
    options: [{
      value: "1",
      label: "MCT"
    }, {
      value: "2",
      label: "EventRecorder"
    }, {
      value: "3",
      label: "WirelessHolter"
    }, {
      value: "4",
      label: "Holter+ MCT"
    }]
  },
  HPDays: {
    name: "Holter+ Days",
    value: "None",
    type: "select",
    fieldId: "HPDays",
    options: getHolterDays()
  },
  duration: {
    name: "Total duration (days)",
    value: "",
    type: "select",
    fieldId: "duration",
    options: getDurationOptions(30)
  },
  // Tachycardia
  TNotify: {
    name: "Notify (bpm)",
    value: "",
    type: "select",
    fieldId: "TNotify",
    isOptional: true,
    options: getTachyValues(true, 160)
  },
  TReport: {
    name: "Report (bpm)",
    value: "",
    type: "select",
    fieldId: "TReport",
    isOptional: true,
    options: getTachyValues(false, 150)
  },
  TNoOfBeats: {
    name: "No of Beats",
    value: "",
    type: "select",
    fieldId: "TNoOfBeats",
    isOptional: true,
    options: getTachyNumbers(true)
  },
  // Bradycardia
  BNotify: {
    name: "Notify (bpm)",
    value: "",
    type: "select",
    fieldId: "BNotify",
    isOptional: true,
    options: getBradyValues(true, 30)
  },
  BReport: {
    name: "Report (bpm)",
    value: "",
    type: "select",
    fieldId: "BReport",
    isOptional: true,
    options: getBradyValues(false, 40)
  },
  BNoOfBeats: {
    name: "No of Beats",
    value: "",
    type: "select",
    fieldId: "BNoOfBeats",
    isOptional: true,
    options: getBradyNumbers(true)
  },
  // Pause
  PNotify: {
    name: "Notify (sec)",
    value: "",
    type: "select",
    fieldId: "PNotify",
    isOptional: true,
    options: getPauseValues(true, 4)
  },
  PReport: {
    name: "Report (sec)",
    value: "",
    type: "select",
    fieldId: "PReport",
    isOptional: true,
    options: getPauseValues(false, 3)
  },
  // additional fibrillation
  reportA: {
    name: "Atrial Fibrillation/Flutter",
    value: "true",
    type: "checkbox",
    fieldId: "reportA"
  },
  isChangeDiagnostics: {
    name: "If the patient does not meet enrollment eligibility criteria, please change the diagnostic test to",
    value: "true",
    type: "checkbox",
    fieldId: "isChangeDiagnostics"
  },
  changedType: {
    name: "Alternative Mode",
    value: "",
    type: "input",
    isOptional: true,
    fieldId: "changedType",
    // defaultValue: {
    //   value: "1",
    //   label: "MCT"
    // },
    options: [{
      value: "Wireless Holter (3+days)",
      label: "Wireless Holter (3+days)"
    }, {
      value: "Wireless Holter (2 days)",
      label: "Wireless Holter (2 days)"
    }, {
      value: "Event Recorder",
      label: "Event Recorder"
    }]
  }
};
