import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const DisclaimerStyle = styled.div`
  padding: ${theme.offsets.mainContentVertical} ${theme.offsets.mainContent};
  .header {
    display: flex;
    margin-bottom: 16px;
    .header-item {
      flex: 1;
    }
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: ${theme.colors.text};
    }
  }
  .content {
    max-width: 888px;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 400;
    a {
      color: ${theme.colors.blue};
      text-decoration: underline;
      font-weight: 600;
    }
  }
`;
