import { applySnapshot, flow, Instance, SnapshotIn, types } from "mobx-state-tree";
import { IUserFormModel, UserFormModel } from "./user.model";
import { InitialState } from "./initial.state";
import { GenderType } from "../../boot/Constants";
import {
  getAllClinicPhysiciansApi,
  addPatientApi,
  updatePatientApi,
  checkDuplicateApi
} from "../../services/api/clinic.api";
import moment from "moment";
import { validateAddressApi } from "../../services/api/user.api";

function findStateId(state: IUserFormModel | undefined) {
  if (state) {
    const active = state.options.find((item:any) => {
      if (state && state.value) {
        return state.value === item.value;
      }
    });
    if (active && active.id) {
      return active.id;
    }
  }
  return ""; // OTHER
}
function getStreetAddress(address: string) {
  const words = address.split(" ");

  const processedWords = words.map(word => {
    if (word.includes("-")) {
      // skip words with more than 2 dashes
      if (word.split("-").length > 2) {
        return word;
      }
      // Remove dash only between numbers
      return word.replace(/(\d)-(\d)/g, "$1$2");
    }
    return word;
  });

  return processedWords.join(" ");
}
export const CreateUserModel = types
  .model("CreateUserModel", {
    // Personal Info
    id: types.identifier,
    firstName: types.maybe(UserFormModel),
    middleName: types.maybe(UserFormModel),
    lastName: types.maybe(UserFormModel),
    birthDate: types.maybe(UserFormModel),
    gender: types.maybe(UserFormModel),
    patientId: types.maybe(UserFormModel),
    // Contact Info
    streetAddress: types.maybe(UserFormModel),
    apartmentNumber: types.maybe(UserFormModel),
    city: types.maybe(UserFormModel),
    state: types.maybe(UserFormModel),
    zip: types.maybe(UserFormModel),
    timeZone: types.maybe(UserFormModel),
    DST: types.maybe(UserFormModel),
    phone: types.maybe(UserFormModel),
    alternativePhone: types.maybe(UserFormModel),
    otherContacts: types.maybe(UserFormModel),
    isPaced: types.maybe(UserFormModel), // no
    ICD10Code: types.maybe(UserFormModel), // no
    medications: types.maybe(UserFormModel),
    // clinicalInformation
    orderingPhysician: types.maybe(UserFormModel),
    referringClinical: types.maybe(UserFormModel),
    readingPhysician: types.maybe(UserFormModel),
    readingReferringClinical: types.maybe(UserFormModel),
    activeClinic: types.maybe(types.frozen({})),
    comment: types.maybe(UserFormModel)
  })
  .actions(self => ({
    setDefaultValueByKey(key: string, defaultValue: any) {
      if (self[key as keyof typeof self]) {
        self[key as keyof typeof self].value = defaultValue.value;
        self[key as keyof typeof self].defaultValue = defaultValue;
        self[key as keyof typeof self].name = self[key as keyof typeof self].name + " ";
      }
    },
    setDefaultValueMultiByKey(key: string, defaultValue: any) {
      if (self[key as keyof typeof self]) {
        self[key as keyof typeof self].defaultValue = defaultValue;
      }
    }
  }))
  .actions(self => ({
    setActiveClinic(defaultValue: any) {
      self.activeClinic = defaultValue;
    }
  }))
  .actions(self => {
    const getDataForSave = () => {
      const getValidPhoneNumber = (value: string) => {
        return "+" + value.replace(/[^\w\s]/gi, "").replace(/\s/g, "");
      };

      const readingPhysician = findStateId(self.readingPhysician);
      const readingPhysicianClinic = findStateId(self.readingReferringClinical);
      const IndForMonitor = self.ICD10Code && self.ICD10Code.valueMulti.map((value: any) => {
        const fullName = self.ICD10Code?.options.find((item: any) => item.value === value);
        if (fullName) return fullName.label;
        return value;
      });
      const data: any = {
        Name: self.firstName && self.firstName.value.trim(), // 30 charters
        LastName: self.lastName && self.lastName.value.trim(), // 50 charters
        MI: self.middleName && self.middleName.value.trim(), // 5
        Gender: self.gender && self.gender.value.slice(0, 1).toUpperCase(), // M, F, O
        IndForMonitor: IndForMonitor && IndForMonitor.join(";"),
        Medications: self.medications && self.medications.value.trim(),
        CardiacDevice: self.isPaced && self.isPaced.isChecked ? "Other" : "None",
        DOB: self.birthDate && self.birthDate.value, // 1991-11-29 00:00:00.000
        Comment: self.comment && self.comment.value,
        Contact1: self.phone && getValidPhoneNumber(self.phone.value), // 50 charters // remove +
        Contact2: self.alternativePhone && getValidPhoneNumber(self.alternativePhone.value), // 50charters
        Contact3: self.otherContacts && self.otherContacts.value, // 50charters
        PatientIDClinic: self.patientId && self.patientId.value, // 30 charters
        TimeZoneStandardName: self.timeZone && self.timeZone.value,
        DST: self.DST && self.DST.value,
        Address: self.streetAddress && self.streetAddress.value.trim(),
        City: self.city && self.city.value.trim(),
        Address2: self.apartmentNumber && self.apartmentNumber.value.trim(),
        IDState: findStateId(self.state),
        Zip: self.zip && self.zip.value,
        IDPhysicianReading: readingPhysician,
        IDPhysicianOrdering: findStateId(self.orderingPhysician),
        IDPhysicianReadingClinic: readingPhysicianClinic,
        IDPhysicianOrderingClinic: findStateId(self.referringClinical)
      };

      for (const [key, value] of Object.entries(data)) {
        if (!value) {
          delete data[key];
        }
      }

      return data;
    };

    const saveForm = flow(function* () {
      const data = getDataForSave();
      const str = JSON.stringify(data);

      try {
        const response = yield addPatientApi(str);
        if (response.ok) {
          return response.data;
        }
        return false;
      } catch (error) {
        return false;
      }
    });

    const checkDuplicate = flow(function* () {
      const data = {
        idClinic: findStateId(self.referringClinical),
        firstName: self.firstName && self.firstName.value.trim(),
        lastName: self.lastName && self.lastName.value.trim(),
        DOB: self.birthDate && self.birthDate.value
      };

      try {
        const response = yield checkDuplicateApi(data);
        if (response.ok) {
          return response.data;
        }
        return [];
      } catch {
        return [];
      }
    });

    const findICDValuesByCode = (values: string[]): any => {
      const additionalValues: any = [];
      const returnedValues: any = [];
      for (let i = 0; i < values.length; i++) {
        const value = values[i];
        if (!value || value === " ") continue;
        const activeValueOption = self.ICD10Code?.options.find((item: any) => value.indexOf(item.value) + 1);
        if (activeValueOption) {
          returnedValues.push(activeValueOption.value);
        } else {
          additionalValues.push(value);
          returnedValues.push(value);
        }
      }
      return {
        returnedValues,
        additionalValues
      };
    };

    const updatePatient = flow(function* (idPatient: string | number) {
      const data : any = getDataForSave();
      data.Id = idPatient.toString();
      const str = JSON.stringify(data);

      try {
        const response = yield updatePatientApi(str);
        if (response.ok) {
          return response.data;
        }
        return null;
      } catch (error) {
        return null;
      }
    });

    const setPatientInfo = (data: any) => {
      const getValidPhoneNumber = (value: string | undefined) => {
        if (!value) return "";
        const formattedValue = value.replace(/[^\w\s]/gi, "").replace(/\s/g, "");
        return formattedValue;
      };

      if (self.comment) {
        self.comment.value = data.Comment || ".";
      }

      if (self.firstName) {
        self.firstName.value = data.Name;
      }
      if (self.lastName) {
        self.lastName.value = data.LastName;
      }
      if (self.middleName) {
        self.middleName.value = data.MI;
      }
      if (self.gender) {
        self.setDefaultValueByKey("gender", data.Gender
          ? {
            value: GenderType[data.Gender as keyof typeof GenderType],
            label: GenderType[data.Gender as keyof typeof GenderType]
          }
          : {
            value: "",
            label: ""
          }
        );
      }
      if (self.ICD10Code) {
        const values = data.IndForMonitor.split(";");
        const filteredObj = findICDValuesByCode(values);
        self.ICD10Code.setOptions([
          ...self.ICD10Code.options,
          ...filteredObj.additionalValues.map((value: string) => ({
            value: value,
            label: value
          }))
        ]);
        self.ICD10Code.setDefaultMultiValue(filteredObj.returnedValues);
        self.ICD10Code.setValueMultiply(filteredObj.returnedValues);
      }
      if (self.medications) {
        self.medications.value = data.Medications;
      }
      if (self.isPaced) {
        self.isPaced.value = data.CardiacDevice === "Other" ? "true" : "false";
        self.isPaced.isChecked = data.CardiacDevice === "Other";
      }
      if (self.birthDate) {
        self.birthDate.value = moment.utc(data.DOB).format("MM/DD/YYYY");
      }
      if (self.phone) {
        self.phone.value = getValidPhoneNumber(data.Contact1);
      }
      if (self.alternativePhone) {
        self.alternativePhone.value = data.Contact2 && getValidPhoneNumber(data.Contact2);
      }
      if (self.otherContacts) {
        self.otherContacts.value = data.Contact3;
      }
      if (self.patientId) {
        self.patientId.value = data.PatientIDClinic;
      }
      if (self.timeZone) {
        self.timeZone.value = data.TimeZoneStandardName;
      }
      if (self.DST) {
        self.DST.value = data.DST;
      }
      if (self.streetAddress) {
        self.streetAddress.value = data.Address || "";
      }
      if (self.apartmentNumber) {
        self.apartmentNumber.value = data.Address2 || "";
      }
      if (self.city) {
        self.city.value = data.City || "";
      }
      if (self.state) {
        self.state.getStatesOptions()
          .then(() => {
            if (self.state) {
              const findState = self.state.options.find((item: any) => item.id.toString() === data.IDState.toString());
              if (findState) {
                self.setDefaultValueByKey("state", {
                  value: findState.value,
                  label: findState.label
                });
              }
            }
          });
      }
      if (self.zip) {
        self.zip.value = data.Zip || "";
      }
      const setActiveClinicUseSH = (physician: any) => {
        const activeClinic = physician.AdditionalClinics?.find((item: any) =>
          item.Id.toString() === data.IDPhysicianOrderingClinic.toString()) || {};
        self.setActiveClinic(activeClinic);
      };
      getAllClinicPhysiciansApi()
        .then((result: any) => {
          if (result.ok && result.data.length) {
            const getAdditionalClinics = (response: any) => {
              return response
                .map((item: any) => ({
                  value: item.Name,
                  label: item.Name,
                  id: item.Id + ""
                }));
            };

            if (self.orderingPhysician) {
              const findItem = result.data
                .find((item: any) => item.Id.toString() === data.IDPhysicianOrdering.toString());

              if (findItem) {
                const dataForSet = {
                  value: findItem.Name + " " + findItem.LastName,
                  label: findItem.Name + " " + findItem.LastName,
                  id: findItem.Id + "",
                  additionalClinics: getAdditionalClinics(findItem.AdditionalClinics)
                };

                setActiveClinicUseSH(findItem);

                self.setDefaultValueByKey("orderingPhysician", {
                  value: dataForSet.value,
                  label: dataForSet.label
                });

                if (self.referringClinical) {
                  self.referringClinical.setOptions(dataForSet.additionalClinics);
                  const findClinic = dataForSet.additionalClinics
                    .find((item: any) => item.id.toString() === data.IDPhysicianOrderingClinic.toString());

                  if (findClinic) {
                    self.setDefaultValueByKey("referringClinical", {
                      value: findClinic.value,
                      label: findClinic.label
                    });
                  }
                }
              }
            }

            if (self.readingPhysician) {
              const findItem = result.data
                .find((item: any) => item.Id.toString() === data.IDPhysicianReading.toString());

              if (findItem) {
                const dataForSet = {
                  value: findItem.Name + " " + findItem.LastName,
                  label: findItem.Name + " " + findItem.LastName,
                  id: findItem.Id + "",
                  additionalClinics: getAdditionalClinics(findItem.AdditionalClinics)
                };

                self.setDefaultValueByKey("readingPhysician", {
                  value: dataForSet.value,
                  label: dataForSet.label
                });

                if (self.readingReferringClinical) {
                  self.readingReferringClinical.setOptions(dataForSet.additionalClinics);
                  const findClinic = dataForSet.additionalClinics
                    .find((item: any) => item.id.toString() === data.IDPhysicianReadingClinic.toString());

                  if (findClinic) {
                    self.setDefaultValueByKey("readingReferringClinical", {
                      value: findClinic.value,
                      label: findClinic.label
                    });
                  }
                }
              }
            }
          }
        });
    };
    async function validateAddress() {
      const st = getStreetAddress(self.streetAddress?.value.trim() || "");
      const street = encodeURIComponent(st + "");
      const city = encodeURIComponent(self.city?.value.trim() + "");
      const zip = encodeURIComponent(self.zip?.value + "");
      const state = encodeURIComponent(self.state?.value + "");
      const secondary = encodeURIComponent(self.apartmentNumber?.value + "");

      try {
        const result = await validateAddressApi(street, city, zip, state, secondary);
        console.log("result", result);
        return result;
      } catch (error) {
        console.error("Access error", error);
        throw error;
      }
    }

    function init(data: any) {
      reset();
      if (!data.patientId) return;
      setPatientInfo(data.patientData);
    }

    function reset() {
      applySnapshot(self, InitialState);
    }

    return {
      saveForm,
      updatePatient,
      validateAddress,
      reset,
      setPatientInfo,
      init,
      checkDuplicate
    };
  });

export interface ICreateUserModel extends Instance<typeof CreateUserModel> {}
export interface ICreateUserModelSnapShot extends SnapshotIn<typeof CreateUserModel> {}
