import { observer } from "mobx-react-lite";
import { DisclaimerStyle } from "./Disclaimer.presents";
import { CLCard } from "../../../components/shared/cl-card";

export const DisclaimerPage = observer(() => {
  return (
    <DisclaimerStyle>
      <CLCard padding="24px">
        <div className="header">
          <div className="header-item title">Disclaimer</div>
        </div>
        <div className="content">
          <p>
            {`The information provided contains general reimbursement information, is only presented for 
            illustrative purposes, and does not constitute reimbursement advice. It is the healthcare provider’s 
            sole responsibility to submit accurate codes, charges and modifiers based on the services rendered 
            and the patient’s medical condition. It is also the provider's sole responsibility to understand 
            and comply with Medicare national coverage determinations (NCD), Medicare local coverage determinations 
            (LCD), and any other specific payer billing requirements. Payer billing, coding and coverage 
            requirements vary from payer to payer, may be updated frequently, and should be verified before 
            treatment for limitations on diagnosis, coding, or service requirements. Rhythm Express recommends 
            you consult with payers, reimbursement specialists, and/or legal counsel regarding all coding, coverage, 
            and reimbursement matters. `}
            <b>
              <i>{`All coding and billing submissions must be truthful and not misleading, and
              require full disclosure for the reimbursement of any service or procedure. `}
              </i>
              {`Rhythm Express specifically disclaims any responsibility for actions or consequences 
              resulting from the use of this information.`}
            </b>
          </p>
          <p>
            {`CPT® Copyright 2022 American Medical Association. All rights reserved. CPT is a 
              trademark of the American Medical Association. Fee schedules, relative value units, conversion 
              factors and/or related components are not assigned by the AMA, are not part of CPT, and the AMA 
              is not recommending their use.`}
          </p>
          <p>
            {`Disclosure: Prior to using the Rhythm Express system, please review the Instructions 
            for Use for a complete listing of indications, contraindications, warnings, precautions 
            and potential adverse events. For full prescribing information, please visit `}
            <a href="http://www.rhythmexpressecg.com" target="_blank" rel="noreferrer">
              http://www.rhythmexpressecg.com
            </a>
          </p>
        </div>
      </CLCard>
    </DisclaimerStyle>
  );
});
