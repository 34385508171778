import { validatePhoneHandler, validatesZipCodeHandler } from "../../services/handlers/validate.handler";

export const ValidateField = (form: any): {
  errors: {[key: string]: string},
  isValid: boolean
  isCompliance: boolean
} => {
  const errors: {[key: string]: string} = {};
  let isValid: boolean = true;
  let isCompliance: boolean = false;
  for (const key in form) {
    const field = form[key];
    if (!field) continue;
    const value = field.value?.trim();
    // reqired field
    if (field.isOptional) {
      continue;
    }
    if (field.validateTimeZone) {
      if (!value) {
        errors.zip = "Please fill USA zip";
        isValid = false;
      }
    }

    if (field && field.fieldId && !field.isOptional && !field.isCompliance) {
      if (field.type === "multiply") {
        if (!value && (!field.valueMulti || !field.valueMulti[0])) {
          errors[field.fieldId] = "Please fill the field";
          isValid = false;
        }
      } else {
        if (!value) {
          errors[field.fieldId] = "Please fill the field";
          isValid = false;
        }
      }
    }

    if (field.isCompliance) {
      if (!value && (!field.valueMulti || !field.valueMulti[0])) {
        errors[field.fieldId] = "compliance_error";
        isCompliance = true;
      }
    }

    if (field.type === "number") {
      if (value.indexOf("_") + 1) {
        isValid = false;
        errors[field.fieldId] = "Please enter valid phone number";
      }
    }
    if (field.validatePhone) {
      if (!validatePhoneHandler(value)) {
        isValid = false;
        errors[field.fieldId] = "Please enter valid phone number";
      }
    }
    if (field.validateZip) {
      if (!validatesZipCodeHandler(value)) {
        isValid = false;
        errors[field.fieldId] = "Zip must be in 00000 or 00000-0000 format";
      }
    }
    if (field.maxLength) {
      if (value.length > field.maxLength) {
        errors[field] = "Max length " + field.maxLength;
        isValid = false;
      }
    }
  }

  return {
    errors,
    isValid,
    isCompliance
  };
};

export const isNumber = (str: string) => {
  const pattern = new RegExp(/^[0-9\b]+$/);
  return pattern.test(str);
};
