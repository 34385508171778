import API from "../../boot/Api";
import * as Types from "./api.types";
import { encodeData } from "../handlers/data.handler";

let clinicsPhysicians: any[] = [];
export const mockAllClinicPhysicians = () => {
  return clinicsPhysicians;
};

export const getAllClinicPhysiciansApi = async(): Promise<Types.ApiResponse> => {
  const result = API.get("/ClinicPhysicians");
  const response = await Types.getResponse(result);
  if (response.ok) {
    clinicsPhysicians = response?.data;
  }
  return response;
};

export const getInsuranceCompaniesApi = async(): Promise<Types.ApiResponse> => {
  const result = API.get("/ClinicPatients/InsuranceCompanies");
  return await Types.getResponse(result);
};

export const addPatientApi = async(data: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    jsonPatient: data
  });
  const result = API.post(`/ClinicPatients/Add?${encodedData}`);
  return await Types.getResponse(result);
};

export const checkDuplicateApi = async(data: any): Promise<Types.ApiResponse> => {
  const result = API.post("/ClinicPatients/SearchPatient", data);
  return await Types.getResponse(result);
};

export const updatePhysicianCommentApi = async(id: string | number, comment: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    idPatient: id,
    physicianComment: comment
  });
  const result = API.post("/ClinicPatients/UpdatePhysicianComment", encodedData);
  return await Types.getResponse(result);
};

export const updatePatientApi = async(data: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    jsonPatient: data
  });
  const result = API.post(`/ClinicPatients/Update?${encodedData}`);
  return await Types.getResponse(result);
};

export const updatePatientAdditionalInfoApi = async(id: string | number, data: string): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    idPatient: id,
    jsonPatientAdditionalInfo: data
  });
  const result = API.post("/ClinicPatients/UpdatePatientAdditionalInfo", encodedData);
  return await Types.getResponse(result);
};
export const getPatientAdditionalInfoApi = async(id: string | number): Promise<Types.ApiResponse> => {
  const result = API.post("/ClinicPatients/GetPatientAdditionalInfo", { idPatient: id });
  return await Types.getResponse(result);
};
