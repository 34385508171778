const footnotesMap = {
  "A#": "The ZIP Code provided was corrected to match the address.",
  "B#": "The city or state spelling was corrected.",
  "C#": "The city, state, or ZIP Code could not be validated. Please check the accuracy of the address.",
  "D#": "No ZIP+4 code is assigned for this address. Verify the address for accuracy.",
  "E#": "Multiple records were found for the same ZIP Code.",
  "F#": "The address could not be located. Check if the street or number is missing or incorrect.",
  "G#": "Address details were adjusted by including information from the addressee field.",
  "H#": "Address is missing apartment or suite number. Please include it for accurate delivery.",
  "I#": "The address is incomplete or incorrect, and multiple ZIP+4 codes were found.",
  "J#": "Two addresses were provided in the input. Use only one address.",
  "K#": "The address was corrected by adjusting the directional prefix (e.g., North, South).",
  "L#": "An address component (like a directional or suffix) was adjusted for accuracy.",
  "LL#": "This address is flagged for LACSLink review, which may involve updates for rural addresses.",
  "M#": "The street name spelling was corrected.",
  "N#": "Address abbreviations were standardized (e.g., Street → St).",
  "O#": "Multiple ZIP+4 codes match this address. The lowest one was used.",
  "P#": "This address is valid but is better known by a different name.",
  "Q#": "The address has a unique ZIP Code.",
  "R#": "The address is not yet validated but is expected to match soon.",
  "S#": "The apartment, suite, or unit information provided is not recognized.",
  "T#": "The address matched a similar record, but components like the street suffix may be incomplete.",
  "U#": "The city name provided is an unofficial name. It was updated to the preferred name.",
  "V#": "The city and state could not be verified for the given ZIP Code. Double-check the information.",
  "W#": "This ZIP Code does not support street delivery. Use a PO Box or General Delivery address.",
  "X#": "The address uses a unique ZIP Code with a default ZIP+4.",
  "Y#": "The address is for a military or diplomatic location.",
  "Z#": "The address was updated due to a ZIP Code change recorded in USPS data."
};

export const interpretFootnotes = (footnotes: any) => {
  const footnotesCodes = footnotes.split("#").filter(Boolean);
  return footnotesCodes.map((code: string) => {
    const fullCode = code + "#";
    const msg = footnotesMap[fullCode as keyof typeof footnotesMap];
    return msg + "\n"
      || "Unknown issue with the address. Please review it for accuracy.";
  }).join(" ");
};

export const interpretDpvFootnotes = (footnotes: any) => {
  // Map of DPV footnotes to their descriptions
  const dpvFootnotesMap: {
    [key: string]: string;
  } = {
    "AA": "Street name, city, state, and ZIP are all valid.",
    "A1": "Address not present in USPS data.",
    "BB": "Entire address is valid.",
    // eslint-disable-next-line max-len
    "CC": "The submitted secondary information (apartment, suite, etc.) was not recognized. Secondary number is NOT REQUIRED for delivery.",
    // eslint-disable-next-line max-len
    "C1": "The submitted secondary information (apartment, suite, etc.) was not recognized. Secondary number IS REQUIRED for delivery.",
    "F1": "Military or diplomatic address.",
    "G1": "General delivery address.",
    "M1": "Primary number (e.g., house number) is missing.",
    "M3": "Primary number (e.g., house number) is invalid.",
    "N1": "Address is missing secondary information (apartment, suite, etc.) which IS REQUIRED for delivery.",
    "PB": "PO Box street style address.",
    "P1": "PO, RR, or HC box number is missing.",
    "P3": "PO, RR, or HC box number is invalid.",
    "RR": "Confirmed address with private mailbox (PMB) info.",
    "R1": "Confirmed address without private mailbox (PMB) info.",
    "R7": "Valid address that doesn't currently receive US Postal Service street delivery.",
    "TA": "Primary number was matched by dropping trailing alpha.",
    "U1": "Address has a 'unique' ZIP Code.",
    // Combined Footnotes
    "AABB": "ZIP, state, city, street name, and primary number match.",
    // eslint-disable-next-line max-len
    "AABBCC": "ZIP, state, city, street name, and primary number match, but secondary does not. A secondary is not required for delivery.",
    // eslint-disable-next-line max-len
    "AAC1": "ZIP, state, city, street name, and primary number match, but secondary does not. A secondary is required for delivery.",
    "AAM1": "ZIP, state, city, and street name match, but the primary number is missing.",
    "AAM3": "ZIP, state, city, and street name match, but the primary number is invalid.",
    // eslint-disable-next-line max-len
    "AAN1": "ZIP, state, city, street name, and primary number match, but there is secondary information such as apartment or suite that would be helpful.",
    // eslint-disable-next-line max-len
    "AABBR1": "ZIP, state, city, street name, and primary number match. Address confirmed without private mailbox (PMB) info."
  };

  if (dpvFootnotesMap[footnotes]) {
    return dpvFootnotesMap[footnotes];
  }

  const parsedFootnotes = footnotes.match(/.{1,2}/g); // Split into 2-character footnotes

  const descriptions = parsedFootnotes.map((fn: string) => dpvFootnotesMap[fn] || "");

  return descriptions.join(" | ");
};

export const getFootnotesMessage = (footnotesData: any) => {
  const isValid = footnotesData.dpv_match_code === "Y";
  if (isValid) {
    return "";
  }
  let errors = interpretDpvFootnotes(footnotesData.dpv_footnotes);
  if (!errors) {
    errors = interpretFootnotes(footnotesData.footnotes);
  }
  return errors;
};
