import { useState } from "react";
import { CLAlertModalWrapper } from "./cl-alert-modal.presets";
import { CLModal } from "../cl-modal";
import { ClAlert } from "../cl-alert";
import { CLBlueButton, CLLightButton } from "../cl-button";
import { CLIcon } from "../cl-icon";
import Alert from "../../../assets/icons/info-circle.svg";

interface PropsType {
  isShow: boolean;
  data: {
    address?: string;
    error?: string;
  },
  handleSave: () => void;
  handleEdit: () => void;
  hideModal?: () => void;
}

export const ClAlertModal = (props: PropsType) => {
  const [showAlert, setShowAlert] = useState(false);

  const onHide = () => {
    props.hideModal?.();
  };
  const onHideAlert = () => {
    setShowAlert(false);
  };

  const handleClick = () => {
    props.handleSave();
  };
  const handleClickEdit = () => {
    onHide();
    props.handleEdit();
  };

  const renderChild = () => {
    return (<CLAlertModalWrapper>
      <div className="modal-container">
        <div className="icon-container">
          <CLIcon
            width={"32px"}
            height={"32px"}
            icon={Alert}
          />
        </div>
        <div className="title">
          Invalid Address Detected
        </div>
        <p className="description-text">
          The address entered could not be validated.<br/>
          This may cause delivery issues.
        </p>
        <p className="description">
          <ul>
            <li>Entered Address: {props.data?.address}</li>
            <li>Error Details: {props.data?.error}</li>
          </ul>
        </p>
        <div className="footer hide-on-print">
          <div className="btn-wrapper">
            <CLLightButton
              click={handleClick}
              text="Confirm & Save"
              width="200px"></CLLightButton>
          </div>
          <div className="btn-wrapper">
            <CLBlueButton
              click={handleClickEdit}
              text="Edit Address"
              width="200px"></CLBlueButton>
          </div>
        </div>
      </div>
    </CLAlertModalWrapper>);
  };

  return (
    <CLModal
      title=""
      show={props.isShow}
      hideCloseBtn={true}
      backdrop={"static"}
      dialogClassName="modal-dialog-small"
      handleHideModal={onHide}
    >
      {renderChild()}
      <ClAlert
        show={showAlert}
        onHide={onHideAlert}
        msg={"Something goes wrong! Please try again."}/>
    </CLModal>
  );
};
