import styled from "styled-components";

export const CLPatientsListHeaderWrapper = styled.div`
  display: flex;

  .margin-block-right {
    margin-right: 24px;
  }

  .create {
    margin-left: auto;
  }
  .active-statuses {
    margin-left: 20px;
  }

  .vq-multiselect__menu {
    padding: 0 !important;

    .vq-multiselect__menu-list {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;
