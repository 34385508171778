import styled from "styled-components";

export const CLAlertModalWrapper = styled.div`
  .modal-container{
    padding: 40px;
  }
  .icon-container {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: #F3F3F3;
    display: flex;
    margin: 0 auto 16px;
    justify-content: center;
    align-items: center;
  }
  .description-text {
    max-width: 400px;
    text-align: center;
    margin: 0 auto 24px;
  }
  .signature-wrapper{
    border: 1px solid #D1D7E3;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    list-style: none;
    padding: 0;
    margin: 0 0 8px;
  }
  .border-container {
    border: 1px solid #D1D7E3;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fields-wrapper {
    max-width: 560px;
    width: 100%;
  }
  .label {
    font-size: 14px;
    line-height: 18px;
    color: #6D6D6D;
    margin-bottom: 8px;
  }
  .field {
    font-size: 16px;
    line-height: 18px;
    color: #484848;
  }
  .link-button{
    position: absolute;
    z-index: 10;
    top: 8px;
    right: 8px;
    text-decoration: none;
    font-size: 12px;
    line-height: 14px;
    color: #656B72;
    &:hover {
      color: #484848;
    }
  }
  .footer{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    .btn-wrapper{
      &:first-child{
        margin-right: 24px;
      }
    }
  }
  .title{
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    color: #484848;
    margin-bottom: 8px;
  }

  .description{
    background: #FEEEEE;
    border-radius: 10px;
    padding: 16px 16px 8px;
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 18px;
    color: #484848;
  }
  .icon-wrapper{
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
  .spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
`;
